.footer-footer {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
  background-color: #000000;
}
.footer-container {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-logo {
  color: var(--dl-color-gray-white);
  font-size: 2em;
  font-weight: bold;
}
.footer-nav {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.footer-nav1 {
  color: var(--dl-color-secondary-gray500);
  margin-left: 0px;
  text-decoration: none;
}
.footer-nav2 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-nav3 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-nav4 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-nav5 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-nav6 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-text {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.footer-text1 {
  color: var(--dl-color-secondary-gray500);
  font-size: 15px;
  font-style: normal;
  font-weight: 100;
  text-decoration: none;
}
.footer-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-800);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.footer-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-text2 {
  color: var(--dl-color-secondary-gray500);
  font-size: 15px;
  font-style: normal;
  text-align: center;
  font-weight: 100;
}
.footer-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-icon {
  fill: var(--dl-color-secondary-gray500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.footer-icon2 {
  fill: var(--dl-color-secondary-gray500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.footer-icon4 {
  fill: var(--dl-color-secondary-gray500);
  width: 24px;
  height: 24px;
}
.footer-root-class-name {
  max-width: 100%;
}
.footer-root-class-name1 {
  max-width: 100%;
}
.footer-root-class-name2 {
  max-width: 100%;
}
.footer-root-class-name3 {
  max-width: 100%;
}
.footer-root-class-name4 {
  width: 100%;
  max-width: 100%;
}
.footer-root-class-name5 {
  max-width: 100%;
}
.footer-root-class-name6 {
  max-width: 100%;
}
@media(max-width: 767px) {
  .footer-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-text1 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-text2 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .footer-footer {
    padding: var(--dl-space-space-unit);
  }
  .footer-text1 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-text2 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
